<template>
    <div  class="setting-plan">
        <div class="programme">
            <div class="programme_title_top">
                模板管理
            </div>
            <div v-for="(item,index) in barList" :key="index"  class="programme_title" :class="{programme_action:count == index}"  @click="sidebarClick(index,item)">
                <span>{{item.name}}</span>
            </div>

        </div>
        <Table class="programme-right" ref="table" :search-btn="false" :tableTitle="tableTitle" :search-time="false" :searchBtn="false"
               :searchUrl="url" :form="form" :back="back" :delete-url="deleteUrl">
        </Table>

    </div>
</template>

<script>
import Table from '../components/Table';

export default {
    name: "SettingDoctorSayInfo",
    components: {
        Table
    },
    data() {
        return {
            count:0,
            barList:[
                {
                    id:5,
                    name:'医生建议'
                },
                {
                    id:2,
                    name:'行为观察'
                },
            ],
            tableTitle: [
                {
                    name: '编号',
                    value: 'num',
                    width : 130
                },
                {
                    name: '建议内容',
                    value: 'content',
                },
            ],
            form: { type: ''},
            back: {
                text: '返回列表'
            },
            url: 'api/template/lst',
            tableData: [],
            deleteUrl: 'api/template/delete',
            dialogFormVisible: false
        };
    },
    mounted() {
        this.form.type = this.barList[this.count].id
    },
    created() {
    },
    methods:{
        /**
         * 侧边栏点击
         * */
        sidebarClick(index,item) {
            this.count = index
            this.form.type = this.barList[this.count].id
            this.$refs.table.onRefresh()
        },
    },
    computed: {},
    watch: {},
}
</script>

<style scoped lang="scss">
.setting-plan {
    display: flex;
}
.el-form{
    width: 500px;
}
.programme {
    width: 231px;
    height: 100%;
    background: #F2F2F2;
    padding: 10px;
    border-radius: 20px;
}

.programme-right {
    min-width: 1484px;
    margin-left: 20px;
}
.programme_title_top {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    padding:20px 30px;
}
.programme_title {
    //width: 231px;
    height: 52px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
}

.programme_action {
    //width: 220px;
    background: #62B5FB;
    color: #fff;
}

</style>
